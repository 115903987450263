export default [{
    title: 'Кабінет',
    icon: 'HomeIcon',
    tag: '4',
    tagVariant: 'light-warning',
    children: [{
            title: 'Головна',
            route: 'dashboard-ecommerce',
        },
        {
            title: 'Аналітика',
            route: 'dashboard-analytics',
        },
        {
            title: 'Транзакції',
            route: 'apps-invoice-list',
        },
        {
            title: 'Водії',
            route: 'apps-drivers-list',
        }
    ],
}, ]